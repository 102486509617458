var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"delete-modal flex items-center justify-center"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onCancel),expression:"onCancel"}],staticClass:"delete-modal--inner p-20",staticStyle:{"background-color":"#F8F8F8"}},[_c('div',{staticClass:"flex editor flex-col mt-2"},[_c('h2',{staticClass:"text-3xl font-bold mb-10"},[_vm._v("QA E-Mail")]),_c('editor',{attrs:{"apiKey":"8spqxq12m8z598gogaydst2muefa6ygx32kigzgt05bazbbp","placeholder":"Hier schreiben","value":_vm.selected,"init":{
          height: 324,
          menubar: false,
          branding: false,
          toolbar_location: 'bottom',
          resize: true,
          skin: 'naked',
          ref: 'editori',
          statusbar: false,
          plugins: [
          'advlist autolink lists link preview anchor',
          'code fullscreen',
          'paste code contextmenu',
          ],
          contextmenu: false,
          toolbar:
          'undo redo formatselect link bold italic backcolor forecolor \
              alignleft aligncenter alignright alignjustify \
              bullist numlist outdent indent \
              fullscreen',
          }},on:{"input":function($event){return _vm.updateContent($event)}}}),_c('div',{staticClass:"self-start mt-10"},[_c('button',{staticClass:"bg-blue-600 text-white text-2xl font-bold mr-5 py-4 px-8",on:{"click":_vm.onQaOpen}},[_vm._v("Senden")]),_c('button',{staticClass:"bg-gray-600 text-white text-2xl font-bold py-4 px-8",on:{"click":_vm.onCancel}},[_vm._v("Abbrechen")])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }