<template>
    <div class="shadow p-16 mt-20 mb-14" style="background-color:#F8F8F8">
        <ImageModal :show="showModal" :source="imageSource" @closed="closedModal" />
        <QaModal v-if="qaShownModal && loggedUser.qa" @onCancel="closeQaModal" @onQaOpen="onQa"/>
        <div v-if="!loading" class="flex justify-between">
            <h2 class="text-3xl font-bold w-3/4">Konversationen <span :class="`status ${qaTicketStatus}`">{{ qaTicketStatus }}</span> <span v-if="qaTicketFrom" class="font-normal text-2xl font-bold ml-5 text-gray-700"> {{ qaTicketFrom }} ticket</span> <span v-if="qaTicketOrder && qaTicketName" class="font-normal text-2xl font-bold ml-5 text-gray-700"> {{ qaTicketOrder }}, {{ qaTicketName }}</span></h2>
            <div v-if="canReply" class="text-2xl cursor-pointer font-bold bg-blue-400 hover:bg-blue-600 transition text-white py-3 px-5">
                <router-link :to="`/tickets/${$route.params.id}`">Antworten</router-link>
            </div>
            <div v-else @click="assignToSelf" class="text-2xl cursor-pointer font-bold bg-blue-400 hover:bg-blue-600 transition text-white py-3 px-5">
                <button>Sich selbst zuordnen und antworten</button>
                <!-- Sich selbst zuordnen und antworten -->
            </div>
            <button v-if="loggedUser.qa" type="button" class="primary-btn" @click.stop="qaShownModal = !qaShownModal">Konflikt melden</button>
        </div>
        <div class="conversation relative mt-14" v-for="comment in comments" :key="comment.id">
            <span :class="comment.isStaff ? 'absolute top-3 left-0 w-14 h-2 bg-green-600' : 'absolute top-3 left-0 w-14 h-2 bg-red-600'"></span>
            <div class="pl-24">
                <h3 class="text-3xl font-semibold">{{ getAuthorName(comment.isStaff, comment.authorName) }}<span class="ml-10 text-2xl text-gray-400 font-normal">{{ comment.created }}</span></h3>
                <h4 class="text-xl text-gray-400 font-bold mt-2" v-if="qaTicketUser && comment.isStaff">Gesendet von: {{ qaTicketUser }}</h4>
                <!-- <div class="text-2xl mt-5 w-3/4" style="white-space: pre-line" v-html="comment.content"> -->
                <div class="text-2xl mt-5 w-3/4" v-html="comment.content"></div>
            </div>
            <div class="attachments" style="gap: 10px" v-if="comment.attachments">
                <div class="repeated" v-for="item in comment.attachments" :key="item">
                    <img v-if="!item.endsWith('pdf')" @click.stop="imageClicked(item)"  :src="item">
                    <a class="transition inline-block hover:bg-gray-200 mt-5 shadow border-2 py-3 px-5 text-2xl font-medium" v-if="item.endsWith('pdf')" :href="item"><i class="mr-5 far fa-file-pdf"></i> {{ getFileName(item) }}</a>
                </div>
            </div>
        </div>
        <div class="tickets-list relative" v-if="loading">
            <Loading class="absolute top-1/2 left-1/2 transform-translate-x-2/4"></Loading>
        </div>
    </div>
</template>

<style lang="scss">
    .attachments {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 15px;
        img {
            width: 100%;
            height: 25rem;
            object-fit: cover;
            cursor: pointer;
        }
    }
    img[alt=facebook] {
        width: 30px;
    }
    img[alt=twitter] {
        width: 30px;
    }
    img[alt=pinterest] {
        width: 30px;
    }
    img[alt=instagram] {
        width: 30px;
    }

    code {
        max-width: 100%;
        display: inline-block;
        white-space: normal;
    }

    @media screen and (max-width: 576px) {
        .primary-btn {
            font-size: 12px !important;
            padding: 5px 10px !important;
            white-space: nowrap;
        }
    }

    .status {
        color: white;
        padding: 5px 10px;
        font-size: 1.3rem;
        border-radius: 4px;
        display: inline-block;
        margin-left: 10px;
    }
    .open {
        background: #E34F32;
    }
    .solved {
        background: #87929D;
    }
    .closed {
        background: black;
    }
</style>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import QaModal from '@/components/QaModal.vue';

    export default {
        data() {
            return {
                showModal: false,
                imageSource: '',
                qaShownModal: false,
            }
        },
        mounted() { 
            this.getTicket(this.$route.params.id);
            this.getQaTicketUser(this.$route.params.id);
            this.getTicketComments(this.$route.params.id);
            const viewedTickets = JSON.parse(window.localStorage.getItem('viewedTickets'));
            if(viewedTickets) {
                if(!viewedTickets.includes(this.$route.params.id)) {
                    window.localStorage.setItem('viewedTickets', JSON.stringify([...viewedTickets, this.$route.params.id]));
                }
            } else {
                window.localStorage.setItem('viewedTickets', JSON.stringify([this.$route.params.id]));
            }
        },
        methods: {
            ...mapActions(['getTicketComments', 'sendQaEmail', 'getQaTicketUser', 'getTicket']),
            getAuthorName(isStaff, author) {
                if(isStaff) {
                    return 'Farbenlöwe Agent';
                } else {
                    // return decodeURIComponent(escape(this.ticket.customer)) || this.ticket.customer;
                    return author;
                }
            },
            imageClicked(source) {
                this.showModal = true;
                this.imageSource = source;
            },
            getFileName(file) {
                return file.split('?name=')[1];
            },
            closedModal() {
                this.showModal = false;
                this.imageSource = '';
            },
            closeQaModal() {
                this.qaShownModal = false;
            },
            onQa(value) {
                this.sendQaEmail({
                    id: this.$route.params.id,
                    content: value
                });
                this.closeQaModal();
            },
            async assignToSelf(){
                try {
                    const response = await this.$axios.put(`tickets/reassign/${this.$route.params.id}`,{
                        userTags: this.loggedUser.tag
                    });
                    if (response.data.success) {
                        this.$router.push(`/tickets/${this.$route.params.id}`);
                    } else {
                        console.error('Failed to reassign ticket');
                    }
                } catch (error) {
                    console.error('Error during reassignment', error);
                }
            }
        },
        computed: {
            ...mapGetters(['comments', 'ticket', 'loggedUser', 'qaTicketUser', 'qaTicketStatus', 'qaTicketFrom', 'qaTicketOrder', 'qaTicketName', 'canReply', 'loading'])
        },
        components: {
            QaModal
        }
    }
</script>
    