<template>
  <div class="delete-modal flex items-center justify-center">
    <div class="delete-modal--inner p-20" v-click-outside="onCancel"  style="background-color:#F8F8F8">
      <div class="flex editor flex-col mt-2">
        <h2 class="text-3xl font-bold mb-10">QA E-Mail</h2>
        <editor
            apiKey="8spqxq12m8z598gogaydst2muefa6ygx32kigzgt05bazbbp"
            placeholder="Hier schreiben"
            :value="selected"
            @input="updateContent($event)"
            :init="{
            height: 324,
            menubar: false,
            branding: false,
            toolbar_location: 'bottom',
            resize: true,
            skin: 'naked',
            ref: 'editori',
            statusbar: false,
            plugins: [
            'advlist autolink lists link preview anchor',
            'code fullscreen',
            'paste code contextmenu',
            ],
            contextmenu: false,
            toolbar:
            'undo redo formatselect link bold italic backcolor forecolor \
                alignleft aligncenter alignright alignjustify \
                bullist numlist outdent indent \
                fullscreen',
            }"
        >
        </editor>
        <div class="self-start mt-10">
            <button class="bg-blue-600 text-white text-2xl font-bold mr-5 py-4 px-8" @click="onQaOpen">Senden</button>
            <button class="bg-gray-600 text-white text-2xl font-bold py-4 px-8" @click="onCancel">Abbrechen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .delete-modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(#000, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    margin: 0 !important;
    z-index: 50;
    .close {
      margin-left: auto;
      margin-right: auto;
      border-radius: 50%;
      border: 15px solid rgb(220, 38, 38);
      width: 15rem;
      height: 15rem;
      display: grid;
      place-items: center;
    }
    &--inner {
      height: min-content;
    }
  }
  @media screen and (max-width: 576px) {
    .editor {
      width: 250px !important;
    }
  }
</style>

<script>
  import Editor from '@tinymce/tinymce-vue';

  export default {
    name: 'QaModal',
    data() {
        return {
            selected: ''
        }
    },
    methods: {
        updateContent(value) {
            this.selected = value;
        },
        onQaOpen() {
            this.$emit('onQaOpen', this.selected)
        },
        onCancel() {
            this.$emit('onCancel')
        },
    },
    components: {
        Editor
    }
  };
</script>
